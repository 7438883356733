<template>
    <b class="countdown_title"> {{count}}  </b>
</template>
<script>
import {getMeetingRow} from '../api/index';
 export default {
    name: 'CountDown',
    props: {
        second: {
            type: Number,	
            default () {
                return 864000;
            },
        },
	},
    data() {
        return {
            count: '', //倒计时
            seconds: ''  // 10天的秒数
        }
    },
    mounted() { 
        this.Time()
    },
    methods: {
        // 天 时 分 秒 格式化函数
        countDown() { 
            let d = parseInt(this.seconds / (24 * 60 * 60))
            d = d < 10 ? "0" + d : d
            let h = parseInt(this.seconds / (60 * 60) % 24);
            h = h < 10 ? "0" + h : h
            let m = parseInt(this.seconds / 60 % 60);
            m = m < 10 ? "0" + m : m
            let s = parseInt(this.seconds % 60);
            s = s < 10 ? "0" + s : s
            this.count = d + '天' + h + '时' + m + '分' + s + '秒'
        },
        //定时器没过1秒参数减1
        Time() {
            setInterval(() => {
                this.seconds -= 1
                this.countDown()
            }, 1000)
        },
    },
    created(){
        let m_id = this.$route.query.m_id; 
        if(m_id==undefined) m_id = this.$store.state.m_id //空m_id          
        getMeetingRow({'m_id':m_id}).then((res)=>{
            let date = new Date(res.data.result.m_time);    
            this.seconds = date.getTime() - new Date().valueOf()
            this.seconds = this.seconds/1000;
            //    console.log(this.seconds);
        })
    }
  
}
</script>
<style scoped>
.countdown_title{
    color: #FF6600;
}
</style>