import store from "../store"; 
import {ElMessage } from 'element-plus' 
import {createRouter, createWebHistory,createWebHashHistory } from "vue-router";

import IndexVue from  '../view/Index'
import NewsVue from  '../view/News'
import DataVue from  '../view/Data'
import ContributionVue from  '../view/Contribution'
import Applicants from  '../view/Applicants'
import Content from  '../view/Content'
import Login from  '../view/Login' 
import Test from  '../view/Test' 

const routes = [
	{
        path:'/test',
        name:'test', 
		component:Test,
        meta: {
			title: 'test',
			type: ''  
		}
    },
	{
        path:'/login',
        name:'login', 
		component:Login,
        meta: {
			title: 'login',
			type: ''  
		}
    },
	{
        path:'/content',
        name:'content', 
		component:Content,
        meta: {
			title: 'content',
			type: ''  
		}
    },
	{
        path:'/',
        name:'index', 
		component:IndexVue,
        meta: {
			title: 'index',
			type: ''  
		}
    },
    {
        path:'/meeting',
        name:'meeting',
        component:IndexVue, 
        meta: {
			title: 'meeting',
			type: ''  
		}
    },
    {
		path: '/data',
		name: 'data',
		component:DataVue,
		meta: {
			title: 'data',
			type: ''  
		}
	},
    {
		path: '/news',
		name: 'news',
		component:NewsVue,
		meta: {
			title: 'news',
			type: ''  
		}
	},
    {
		path: '/contribution',
		name: 'contribution',
		component:ContributionVue,
		meta: {
			title: 'contribution',
			type: ''  
		}
	},
	{
		path: '/applicants',
		name: 'applicants',
		component:Applicants,
		meta: {
			title: 'applicants',
			type: ''  
		}
	},
	// {
	// 	path: '*', 
	// 	redirect: '/meeting',
	// 	name: 'notFound',
	// 	hidden: true
	// }
]
const router = createRouter({
    history: createWebHashHistory(),
	routes
})
 

router.beforeEach((to, from, next) => {
	
	const route = ['contribution',  "applicants", "data"];//需要登录的路由
 
	store.commit("setShow",store.getters.getShow) 
    let isLogin = store.getters.getPhone;  
	if (route.indexOf(to.name) >= 0) {
		if (isLogin == '') {
			// ElMessage.warning({
			// 	showClose: true,
			// 	message: '请先登录!',
			// 	type: 'warning',
			//   }) 
			store.commit("setDialogVisible",true)
			router.replace({ path: '/'});
		}
	}
	// 已登录状态；当路由到login时，跳转至home 
	// console.log(to.name)
	// localStorage.setItem('routerName', to.name)
	// if (to.name === 'login') {
	// 	if (isLogin != '') {
	// 		router.push({
	// 			path: '/?m_id='+store.getters.getMid, 
	// 		});
	// 	}
	// }
	next();
});


export default router

