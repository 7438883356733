<template> 
 <!-- <el-button text @click="dialogVisible = true" >点击</el-button>  -->
  <el-dialog  
    v-model="dialogVisible"  
    center
    width="540px"
    :show-close = "showClose"
    :before-close="handleClose"
  >  
    <LoginVue></LoginVue> 
  </el-dialog> 
    <Header :headerData="headerData" :show="show" :column="column" :mstime="mstime"></Header> 
    <router-view></router-view> 
    <Footer></Footer> 
</template>

<script>
  
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import {getMeetingRow} from './api/index'; 
import LoginVue from './view/Login.vue'
export default {
  name: 'App',
  components: {
      Header,
      Footer,
      LoginVue
  },
    data() {
        return {  
            // dialogVisible:this.$store.state.dialogVisible,
            showClose:false,
            mstime:'',
            headerData: [
                {
                    name: '参会主页', 
                    path: '/'
                }, {
                    name: '参会报名', 
                    path: '/applicants'
                }, {
                    name: '论文投稿', 
                    path: '/contribution'
                }, {
                    name: '会议动态', 
                    path: '/news'
                }, 
                {
                    name: '参会资料', 
                    path: '/data'
                } 
            ], 
        }
  },
  computed:{
    show(){   
      // return  this.$store.getters.getShow 
      return  this.$store.state.show;  
    },
    column(){
      let column =  this.$route.path;
      if(column=='/content') column='/news' 
      return  column;
    },
    dialogVisible(){

      // let visible =  this.$store.state.dialogVisible
      // this.$store.commit("setDialogVisible",false) 
      
      // return  visible;
      return  this.$store.state.dialogVisible

    }

  },
  methods:{
      getvisible(){
        let visible =  this.$store.state.dialogVisible
        this.$store.commit("setDialogVisible",false) 
        
        return  visible;
      },
      handleClose(){
         this.$store.commit("setDialogVisible",false) 
      },
      _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  
  mounted(){
   
  },
  async created(){ 
      let m_id = this.$route.query.m_id; 
      if(m_id==undefined) m_id = this.$store.getters.getMid //空m_id 
      const res =  await getMeetingRow({'m_id':m_id});
        let   row = res.data.result;   
      
      console.log(row);
      document.title =  row.m_title
      this.mstime= row.m_time
  }
}
</script>

<style>
#app {
  font-family:MicrosoftYaHei,Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; 
}
a{
  text-decoration: none;
}
li{
  list-style: none;
}
div,body,li,ul{
  margin:0;
  padding:0;
}
.router-link-active {
    text-decoration: none; 
    color:#000000;
  }
  a{
    text-decoration: none;
     color:#000000;
  }
  
</style>
