<template>
<el-row>
  <el-col :span="24" class="footer">
    <div class="footer_text">
      <p>  Copyright© 东莞理工学院-机械工程学院 </p> 
       <p> 地址：东莞市松山湖大学路1号东莞理工学院</p> 
       <p> 本站由：<a href="https://www.58hr.net/" target="__blank"> 广东智造人才信息技术科技有限公司</a>  提供技术支持 </p>
    </div>  
  </el-col>
</el-row>

</template> 
<script> 
export default {
  name: 'FooterVue',
  
}
</script>

<style scoped> 
  .footer{
    height: 150px;
    background: #212121;
    padding-top: 30px;
  }
  .footer_text{
    font-size: 14px;
    text-align: center;
    color: #929292;
  }
  .footer_text p{
    margin: 0;
    line-height: 30px;
  }
  a{
    color: #929292;
  }
</style>
